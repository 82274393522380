"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitEmail = exports.submitLead = void 0;
var API_SALESFORCE_FORM = (_a = process.env.API_ONE_APPLICATION) !== null && _a !== void 0 ? _a : '';
var API_KEY = (_b = process.env.API_KEY) !== null && _b !== void 0 ? _b : '';
var APPLICATION_ID = (_c = process.env.APPLICATION_ID) !== null && _c !== void 0 ? _c : '';
var submitEmail = function (fields, configUrl, imageUrl, productInterest, emailConsent) { return __awaiter(void 0, void 0, void 0, function () {
    var searchParams, headers, product;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                searchParams = new URLSearchParams(__assign(__assign({}, fields), { configurationUrl: configUrl !== null && configUrl !== void 0 ? configUrl : '', imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '', emailConsent: emailConsent !== null && emailConsent !== void 0 ? emailConsent : '' }));
                headers = new Headers();
                headers.append('X-Header-AppId', APPLICATION_ID);
                headers.append('X-Header-ApiKey', API_KEY);
                product = productInterest === null || productInterest === void 0 ? void 0 : productInterest.replace(' ', '-').toLowerCase();
                return [4 /*yield*/, fetch("".concat(API_SALESFORCE_FORM, "/api-dispatcher/email-").concat(product, "?").concat(searchParams.toString()), {
                        method: 'POST',
                        headers: headers
                    }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, Promise.reject(new Error("EmailLead post failed: ".concat(response.statusText)))];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: return [4 /*yield*/, response.json()];
                                case 3: return [2 /*return*/, _a.sent()];
                            }
                        });
                    }); })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
exports.submitEmail = submitEmail;
var submitLead = function (fields, market, configUrl, imageUrl, productInterest, emailConsent) { return __awaiter(void 0, void 0, void 0, function () {
    var searchParams, headers, product, externalMarket;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                searchParams = new URLSearchParams(__assign(__assign({}, fields), { configurationUrl: configUrl !== null && configUrl !== void 0 ? configUrl : '', imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : '', emailConsent: emailConsent !== null && emailConsent !== void 0 ? emailConsent : '' }));
                headers = new Headers();
                headers.append('X-Header-AppId', APPLICATION_ID);
                headers.append('X-Header-ApiKey', API_KEY);
                product = productInterest === null || productInterest === void 0 ? void 0 : productInterest.replace(' ', '-').toLowerCase();
                externalMarket = market && market !== 'en';
                return [4 /*yield*/, fetch("".concat(API_SALESFORCE_FORM, "/api-dispatcher/leads-").concat(product).concat(externalMarket ? "-".concat(market) : '', "?").concat(searchParams.toString()), {
                        method: 'POST',
                        headers: headers
                    }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!response.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, Promise.reject(new Error("SubmitLead post failed: ".concat(response.statusText)))];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2: return [4 /*yield*/, response.json()];
                                case 3: return [2 /*return*/, _a.sent()];
                            }
                        });
                    }); })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
exports.submitLead = submitLead;
