"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var getDefaultPaths = function () { return ({
    total: 5,
    offset: 0,
    limit: 5,
    data: [
        {
            model: 'anthem',
            'pdp path': '/trucks/anthem/',
            'configurator path': '/#/anthem'
        },
        {
            model: 'granite',
            'pdp path': '/trucks/granite-series/',
            'configurator path': '/#/granite'
        },
        {
            model: 'pinnacle',
            'pdp path': '/trucks/pinnacle/',
            'configurator path': '/#/pinnacle'
        },
        {
            model: 'lr',
            'pdp path': '/trucks/anthem/',
            'configurator path': '/#/lr'
        },
        {
            model: 'md',
            'pdp path': '/trucks/md-series/',
            'configurator path': '/#/md'
        }
    ],
    ':type': 'sheet'
}); };
var usePaths = function () {
    var _a = (0, react_1.useState)(null), paths = _a[0], setPaths = _a[1];
    (0, react_1.useEffect)(function () {
        var meta = document.querySelector('meta[name="truck-configurator"]');
        if (meta) {
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            fetch(meta.content, { method: 'GET', headers: headers })
                .then(function (res) {
                if (res.ok) {
                    return res.json();
                }
                else {
                    return Promise.reject(new Error('Failed to fetch trucks data: ' + res.statusText));
                }
            })
                .then(function (json) {
                setPaths(json);
            })
                .catch(function (error) {
                console.error(error);
            });
        }
        else {
            setPaths(getDefaultPaths());
        }
    }, [setPaths]);
    return paths;
};
exports.default = usePaths;
