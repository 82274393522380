"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var zustand_1 = require("zustand");
var enums_1 = require("~utils/enums");
var useSection = (0, zustand_1.create)(function (set, get) { return ({
    sections: [],
    currentSection: 0,
    currentStep: 0,
    showSteps: false,
    modalSection: '',
    getCurrentSection: function () {
        return get().sections[get().currentSection];
    },
    setCurrentSection: function (index) {
        set({ currentSection: index });
    },
    getCurrentStep: function () {
        var _a;
        return ((_a = get().sections[get().currentSection]) === null || _a === void 0 ? void 0 : _a.steps[get().currentStep]) || '';
    },
    setCurrentStep: function (index) {
        set({ currentStep: index });
    },
    setShowSteps: function (value) {
        set({ showSteps: value });
    },
    getProgress: function () {
        var sections = get().sections;
        var totalSteps = Math.max(0, sections.reduce(function (acc, x) { return acc + x.steps.length; }, 0) - 1);
        var currentSection = get().currentSection;
        if (!totalSteps)
            return 0;
        var completedSteps = get().currentStep;
        for (var i = 0; i < currentSection; i++) {
            completedSteps += sections[i].steps.length;
        }
        return completedSteps / totalSteps;
    },
    nextStep: function () {
        var _a, _b;
        var sections = get().sections;
        var currentSection = get().currentSection;
        var currentStep = get().currentStep;
        if (currentStep < ((_a = sections[currentSection]) === null || _a === void 0 ? void 0 : _a.steps.length) - 1) {
            set({ currentStep: currentStep + 1 });
        }
        else if (currentSection < sections.length - 1) {
            set({
                currentSection: currentSection + 1,
                currentStep: 0,
                showSteps: ((_b = sections[currentSection + 1]) === null || _b === void 0 ? void 0 : _b.steps.length) > 1
            });
        }
    },
    nextSection: function () {
        var sections = get().sections;
        var currentSection = get().currentSection;
        if (currentSection < sections.length - 1) {
            set({ currentSection: currentSection + 1, currentStep: 0 });
        }
    },
    setSectionsFromProduct: function (product, appData, selection) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var sections = [];
        var interiorHotspots = (_b = (_a = product.component_groups) === null || _a === void 0 ? void 0 : _a.find(function (g) { return g.name === enums_1.SectionId.Interior; })) === null || _b === void 0 ? void 0 : _b.components.find(function (c) { return c.key === 'hotspots'; });
        var exteriorHotspots = (_d = (_c = product.component_groups) === null || _c === void 0 ? void 0 : _c.find(function (g) { return g.name === enums_1.SectionId.Exterior; })) === null || _d === void 0 ? void 0 : _d.components.find(function (c) { return c.key === 'hotspots'; });
        var _loop_1 = function (group) {
            var steps = [];
            if (group.name === 'hidden') {
                return "continue";
            }
            var groupSection = appData["".concat(product.name, ".").concat(group.name)];
            if (groupSection) {
                var n_1 = 0;
                while (true) {
                    var name_1 = groupSection.key_values["step_".concat(n_1, "_title")];
                    var id = groupSection.key_values["step_".concat(n_1, "_id")];
                    var components = group.components.filter(function (c) {
                        var stepAttr = c.attribute_map[enums_1.AttributeType.Step];
                        return stepAttr && stepAttr === "".concat(n_1) && c.available_variations.length;
                    });
                    if (id && name_1) {
                        if (components.some(function (c) { var _a; return !!((_a = selection[group.name]) === null || _a === void 0 ? void 0 : _a[c.key]); }) && components.length) {
                            steps.push({
                                name: name_1,
                                id: id,
                                components: components
                            });
                        }
                    }
                    else {
                        break;
                    }
                    n_1++;
                }
            }
            var hotspots = group.name === enums_1.SectionId.Interior ? interiorHotspots : exteriorHotspots;
            sections.push({
                name: (_f = appData["".concat(product.name, ".").concat(group.name)]) === null || _f === void 0 ? void 0 : _f.section_name_localized,
                id: group.name,
                steps: steps,
                hotspots: hotspots
            });
        };
        for (var _i = 0, _j = (_e = product.component_groups) !== null && _e !== void 0 ? _e : []; _i < _j.length; _i++) {
            var group = _j[_i];
            _loop_1(group);
        }
        sections.push({
            name: (_g = appData['summary_sections']) === null || _g === void 0 ? void 0 : _g.section_name_localized,
            id: 'summary',
            steps: [
                {
                    name: (_h = appData['summary_sections']) === null || _h === void 0 ? void 0 : _h.section_name_localized,
                    id: 'summary',
                    components: []
                }
            ]
        });
        set({ sections: sections });
    },
    setModalSection: function (modalSection) {
        set({ modalSection: modalSection });
    }
}); });
exports.default = useSection;
