"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var zustand_1 = require("zustand");
var enums_1 = require("~utils/enums");
var componentsToDefault = [
    'exterior.exhaust',
    'exterior.air_horns',
    'interior.rear_exit',
    'chassis.wheelbase'
];
var getEmissionLevels = function () {
    var tag = document.querySelector('meta[name="emission-levels"]');
    if (tag) {
        return new Set(tag.content.split(','));
    }
    return new Set();
};
var useConfiguration = (0, zustand_1.create)(function (set, get) { return ({
    models: [],
    currentModel: 0,
    setModels: function (value) { return set({ models: value.filter(function (m) { return m.category_id === 3; }) }); },
    getCurrentModel: function () { return get().models[get().currentModel]; },
    setCurrentModel: function (model) {
        var index = get().models.findIndex(function (x) { return x.name === model; });
        if (index !== -1) {
            set({ currentModel: index });
        }
        get().setDefaultOptions(get().models[index]);
    },
    customColor: '#ffffff',
    setCustomColor: function (value) { return set({ customColor: value }); },
    branding: { name: '', assets: {} },
    setBrandingSlot: function (slot, assetName) {
        var newBranding = __assign({}, get().branding);
        newBranding.assets[slot] = assetName;
        set({ branding: newBranding });
    },
    setBrandingSlots: function (slots) {
        var newBranding = __assign({}, get().branding);
        slots.forEach(function (_a) {
            var slot = _a.slot, assetName = _a.assetName;
            newBranding.assets[slot] = assetName;
        });
        set({ branding: newBranding });
    },
    setBrandingName: function (name) {
        var newBranding = __assign({}, get().branding);
        newBranding.name = name;
        set({ branding: newBranding });
    },
    isDealer: false,
    setIsDealer: function (value) { return set({ isDealer: value }); },
    selectedFeatures: {},
    setLaunchOptions: function (selectedFeatures) {
        componentsToDefault.forEach(function (item) {
            var _a = item.split('.'), group = _a[0], component = _a[1];
            var defaultOption = get().getDefaultOption(group, component, selectedFeatures);
            if (defaultOption) {
                if (!selectedFeatures[group]) {
                    selectedFeatures[group] = {};
                }
                selectedFeatures[group][component] = defaultOption.key;
            }
            else {
                console.warn("No default option found for component: ".concat(component, " in group: ").concat(group));
            }
        });
    },
    setSelectedFeature: function (group, feature, value) {
        var selectedFeatures = __assign({}, get().selectedFeatures);
        if (!selectedFeatures[group]) {
            selectedFeatures[group] = {};
        }
        selectedFeatures[group][feature] = value;
        if (feature === 'cabs') {
            get().setLaunchOptions(selectedFeatures);
        }
        // This selection might impact other choices through dependencies
        get().applyDependencies(selectedFeatures);
        set({ selectedFeatures: selectedFeatures });
    },
    setSelectedFeatures: function (features) {
        set({ selectedFeatures: features });
    },
    getFeature: function (group, feature) {
        var _a;
        var currentModel = get().getCurrentModel();
        var componentGroup = (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.name === group; });
        var component = componentGroup === null || componentGroup === void 0 ? void 0 : componentGroup.components.find(function (x) { return x.key === feature; });
        return component;
    },
    getDefaultOption: function (group, feature, selectedFeatures) {
        var _a;
        var component = get().getFeature(group, feature);
        var selected = selectedFeatures !== null && selectedFeatures !== void 0 ? selectedFeatures : get().selectedFeatures;
        if (component) {
            var variations = get()
                .filterOptions(component, selected)
                .filter(function (x) { return !x.original_key.startsWith('tab_'); });
            return (_a = variations === null || variations === void 0 ? void 0 : variations.find(function (x) { return x.is_default; })) !== null && _a !== void 0 ? _a : variations[0];
        }
        return undefined;
    },
    getGroup: function (group) {
        var currentModel = get().getCurrentModel();
        if (currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) {
            return currentModel.component_groups.find(function (x) { return x.name === group; });
        }
        return undefined;
    },
    filterOptions: function (component, selectedFeatures) {
        var _a, _b;
        var selection = selectedFeatures !== null && selectedFeatures !== void 0 ? selectedFeatures : get().selectedFeatures;
        var emissionLevels = get().emissionLevels;
        var hasAero = [
            'side_roof_fairings',
            'side_roof_chassis_fairings',
            'side_mid_roof_fairings'
        ].includes((_a = selection.aerodynamics) === null || _a === void 0 ? void 0 : _a.aero_package);
        var variations = (_b = component === null || component === void 0 ? void 0 : component.available_variations) !== null && _b !== void 0 ? _b : [];
        return variations.filter(function (variation) {
            var _a;
            var dependencies = variation.dependencies;
            // These dependencies are too complex for OneCX, so we need to hardcode them
            var highRoofMarkers = ['rectangular_flush', 'rectangular_semi_flush'].includes(variation.original_key);
            var isSleeper = ((_a = selection.body) === null || _a === void 0 ? void 0 : _a.cabs) === '70_sleeper';
            if ((component === null || component === void 0 ? void 0 : component.key) === 'marker_lights' && highRoofMarkers !== hasAero && !isSleeper) {
                return false;
            }
            var noVisor = hasAero && !isSleeper;
            if ((component === null || component === void 0 ? void 0 : component.key) === 'sun_visor' && variation.original_key === 'visor_body' && noVisor) {
                return false;
            }
            if ((component === null || component === void 0 ? void 0 : component.key) === 'emission_level' && !emissionLevels.has(variation.original_key)) {
                return false;
            }
            return !(dependencies === null || dependencies === void 0 ? void 0 : dependencies.find(function (dep) { var _a; return ((_a = selection[dep.group]) === null || _a === void 0 ? void 0 : _a[dep.component]) === dep.variation && dep.variation !== undefined; }));
        });
    },
    applyDependencies: function (selection, truckfinder) {
        var _a, _b, _c;
        if (truckfinder === void 0) { truckfinder = false; }
        var filterOptions = get().filterOptions;
        var data = truckfinder ? get().truckfinderData : get().getCurrentModel();
        for (var _i = 0, _d = (_a = data === null || data === void 0 ? void 0 : data.component_groups) !== null && _a !== void 0 ? _a : []; _i < _d.length; _i++) {
            var group = _d[_i];
            var _loop_1 = function (component) {
                if (component.key === 'branding')
                    return "continue";
                if (component.key === 'hotspots')
                    return "continue";
                var variations = filterOptions(component, selection);
                var selected = (_b = selection[group.name]) === null || _b === void 0 ? void 0 : _b[component.key];
                if (!variations.find(function (v) { return v.original_key === selected; }) && !(selected === null || selected === void 0 ? void 0 : selected.startsWith('#'))) {
                    var defaultOption = (_c = get().getDefaultOption(group.name, component.key, selection)) === null || _c === void 0 ? void 0 : _c.original_key;
                    if (defaultOption) {
                        if (!selection[group.name]) {
                            selection[group.name] = {};
                        }
                        console.log('Changing value for ' + component.key, selection[group.name][component.key], ' to ', defaultOption);
                        selection[group.name][component.key] = defaultOption;
                    }
                    else if (selected) {
                        console.log('Deleting selection for ' + component.key);
                        delete selection[group.name][component.key];
                    }
                }
            };
            for (var _e = 0, _f = group.components; _e < _f.length; _e++) {
                var component = _f[_e];
                _loop_1(component);
            }
        }
    },
    setDefaultOptions: function (product, overrides) {
        var _a;
        var currentModel = product !== null && product !== void 0 ? product : get().getCurrentModel();
        var filterOptions = get().filterOptions;
        var newSelection = {};
        (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.forEach(function (group) {
            var _a;
            var typeAttributeId = (_a = group.attributes.find(function (attr) { return attr.name === enums_1.AttributeType.Type; })) === null || _a === void 0 ? void 0 : _a.id;
            var noTabs = function (v) {
                return !v.attribute_values.find(function (attr) { return attr.attribute_id === typeAttributeId && attr.value === 'Filter'; });
            };
            group.components.forEach(function (component) {
                var _a;
                if (['branding', 'hotspots'].includes(component.key))
                    return;
                var variations = filterOptions(component, newSelection).filter(noTabs);
                var defaultVariant = (_a = variations.find(function (v) { return v.is_default; })) !== null && _a !== void 0 ? _a : variations[0];
                if (!newSelection[group.name]) {
                    newSelection[group.name] = {};
                }
                if (defaultVariant === null || defaultVariant === void 0 ? void 0 : defaultVariant.original_key) {
                    newSelection[group.name][component.key] = defaultVariant.original_key;
                }
            });
        });
        if (overrides) {
            newSelection = __assign(__assign({}, newSelection), overrides);
        }
        set({ selectedFeatures: newSelection });
    },
    getConfigurationString: function (fname, lname, product, options) {
        var _a;
        var config = [];
        var selectedFeatures = options !== null && options !== void 0 ? options : get().selectedFeatures;
        var branding = get().branding;
        var currentModel = product !== null && product !== void 0 ? product : get().getCurrentModel();
        if (!currentModel) {
            return null;
        }
        var _loop_2 = function (groupName) {
            var group = (_a = currentModel === null || currentModel === void 0 ? void 0 : currentModel.component_groups) === null || _a === void 0 ? void 0 : _a.find(function (g) { return g.name === groupName; });
            var _loop_3 = function (componentName) {
                var component = group === null || group === void 0 ? void 0 : group.components.find(function (c) { return c.key === componentName; });
                var variationName = selectedFeatures[groupName][componentName];
                var variation = component === null || component === void 0 ? void 0 : component.available_variations.find(function (v) { return v.original_key === variationName; });
                if (component && variation) {
                    config.push(component === null || component === void 0 ? void 0 : component.id, variation.id);
                }
                else if (component && variationName.startsWith('#')) {
                    config.push(component.id, variationName);
                }
            };
            for (var _c = 0, _d = Object.keys(selectedFeatures[groupName]); _c < _d.length; _c++) {
                var componentName = _d[_c];
                _loop_3(componentName);
            }
        };
        for (var _i = 0, _b = Object.keys(selectedFeatures); _i < _b.length; _i++) {
            var groupName = _b[_i];
            _loop_2(groupName);
        }
        var response = {
            c: __spreadArray([currentModel === null || currentModel === void 0 ? void 0 : currentModel.id], config, true)
        };
        if (Object.keys(branding.assets).length) {
            response.bn = branding.name;
            response.a = branding.assets;
        }
        if (fname && lname) {
            response.f = fname;
            response.l = lname;
        }
        return btoa(JSON.stringify(response));
    },
    parseConfigurationString: function (config) {
        var _a;
        var obj = JSON.parse(atob(config));
        var result = {
            model: '',
            selectedFeatures: {},
            branding: obj.bn && obj.a
                ? {
                    name: obj.bn,
                    assets: obj.a
                }
                : undefined,
            fname: obj.f,
            lname: obj.l
        };
        var models = get().models;
        if (!models) {
            return null;
        }
        var model = models.find(function (m) { return m.id === obj.c[0]; });
        if (!model) {
            return null;
        }
        result.model = model.name;
        var _loop_4 = function (i) {
            var key = obj.c[i];
            var value = obj.c[i + 1];
            if (key !== undefined && value !== undefined) {
                var componentId_1 = key;
                var variationId_1 = value;
                var group = (_a = model === null || model === void 0 ? void 0 : model.component_groups) === null || _a === void 0 ? void 0 : _a.find(function (g) {
                    return g.components.find(function (c) { return c.id === componentId_1; });
                });
                var component = group === null || group === void 0 ? void 0 : group.components.find(function (c) { return c.id === componentId_1; });
                if (group && component) {
                    if (!result.selectedFeatures[group.name]) {
                        result.selectedFeatures[group.name] = {};
                    }
                    if (typeof value === 'string') {
                        result.selectedFeatures[group.name][component.key] = value;
                    }
                    else {
                        var variation = component === null || component === void 0 ? void 0 : component.available_variations.find(function (v) { return v.id === variationId_1; });
                        if (variation) {
                            result.selectedFeatures[group.name][component.key] = variation.original_key;
                        }
                    }
                }
            }
        };
        for (var i = 1; i < obj.c.length; i += 2) {
            _loop_4(i);
        }
        return result;
    },
    truckfinderData: null,
    setTruckfinderData: function (product) { return set({ truckfinderData: product }); },
    emissionLevels: getEmissionLevels()
}); });
exports.default = useConfiguration;
