"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var zustand_1 = require("zustand");
var types_1 = require("~clients/OneRodManager/types");
var useViewportState = (0, zustand_1.create)(function (set, get) { return ({
    showFullscreen: false,
    setShowFullscreen: function (value) { return set({ showFullscreen: value }); },
    show360Toggle: true,
    setShow360Toggle: function (value) { return set({ show360Toggle: value }); },
    showTurntable: false,
    setShowTurntable: function (value) { return set({ showTurntable: value }); },
    showEnvironmentToggle: true,
    setShowEnvironmentToggle: function (value) { return set({ showEnvironmentToggle: value }); },
    setIsLoading: function (isLoading) { return set({ isImageLoading: isLoading }); },
    isImageLoading: false,
    loadingOverlayVisible: false,
    showUI: true,
    loadingOverlayText: 'Applying template design to your Mack...',
    loadingOverlayProgress: 0,
    setLoadingOverlayProgress: function (progress) { return set({ loadingOverlayProgress: progress }); },
    setShowUI: function (show) { return set({ showUI: show }); },
    setShowPagination: function (show) { return set({ showPagination: show }); },
    showPagination: true,
    showStudio: false,
    setShowStudio: function (value) { return set({ showStudio: value }); },
    pagination: '',
    setPagination: function (value) { return set({ pagination: value }); },
    imageStatus: types_1.ImageStatus.None,
    setImageStatus: function (status) { return set({ imageStatus: status }); },
    showHotspots: false,
    setShowHotspots: function (value) { return set({ showHotspots: value }); },
    showHotspotsToggle: true,
    setShowHotspotsToggle: function (value) { return set({ showHotspotsToggle: value }); },
    scrollTargetIndex: -1,
    setScrollTargetIndex: function (value) { return set({ scrollTargetIndex: value }); },
    slideshowPage: 0,
    setSlideshowPage: function (value) { return set({ slideshowPage: value }); }
}); });
exports.default = useViewportState;
