"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorWayAttributes = exports.SectionId = exports.AttributeType = void 0;
var AttributeType;
(function (AttributeType) {
    AttributeType["Type"] = "Type";
    AttributeType["RenderDefault"] = "Render Default";
    AttributeType["RenderVisible"] = "Render Visible";
    AttributeType["ModalSection"] = "Modal Section";
    AttributeType["UptimeAsset"] = "Uptime Asset";
    AttributeType["HasInfo"] = "Has Info";
    AttributeType["Included"] = "Included";
    AttributeType["Step"] = "Step";
    AttributeType["ViewportImage"] = "Viewport Image";
    AttributeType["ColorWay"] = "Color Way";
    AttributeType["RenderImage"] = "Render Image";
    AttributeType["IsPaint"] = "Is Paint";
    AttributeType["SummaryDescription"] = "Summary Description";
    AttributeType["CabType"] = "Cab Type";
})(AttributeType = exports.AttributeType || (exports.AttributeType = {}));
var SectionId;
(function (SectionId) {
    SectionId["Body"] = "body";
    SectionId["Exterior"] = "exterior";
    SectionId["Aerodynamics"] = "aerodynamics";
    SectionId["Interior"] = "interior";
    SectionId["AxleSuspension"] = "axle_suspension";
    SectionId["Powertrain"] = "powertrain";
    SectionId["Chassis"] = "chassis";
    SectionId["UptimeSolutions"] = "uptime_solutions";
})(SectionId = exports.SectionId || (exports.SectionId = {}));
var ColorWayAttributes;
(function (ColorWayAttributes) {
    ColorWayAttributes["SierraTan"] = "Sierra Tan";
    ColorWayAttributes["SteelGrey"] = "Steel Grey";
    ColorWayAttributes["Both"] = "Both";
})(ColorWayAttributes = exports.ColorWayAttributes || (exports.ColorWayAttributes = {}));
