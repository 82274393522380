"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var processProduct = function (product) {
    var _a;
    var findDependencyById = function (id) {
        var _a;
        for (var _i = 0, _b = (_a = product.component_groups) !== null && _a !== void 0 ? _a : []; _i < _b.length; _i++) {
            var group = _b[_i];
            for (var _c = 0, _d = group.components; _c < _d.length; _c++) {
                var comp = _d[_c];
                var match = comp.available_variations.find(function (v) { return v.id === id; });
                if (match) {
                    return { group: group.name, component: comp.key, variation: match.original_key };
                }
            }
        }
    };
    (_a = product.component_groups) === null || _a === void 0 ? void 0 : _a.forEach(function (group) {
        var attrIdMap = {};
        group.attributes.forEach(function (attr) {
            attrIdMap[attr.id] = attr.name;
        });
        group.components.forEach(function (component) {
            component.attribute_map = {};
            component.attribute_values.forEach(function (attr) {
                var name = attrIdMap[attr.attribute_id];
                if (name)
                    component.attribute_map[name] = attr.value;
            });
            component.available_variations.forEach(function (variation) {
                var _a;
                variation.attribute_map = {};
                variation.attribute_values.forEach(function (attr) {
                    var name = attrIdMap[attr.attribute_id];
                    if (name)
                        variation.attribute_map[name] = attr.value;
                });
                (_a = variation.dependencies) === null || _a === void 0 ? void 0 : _a.forEach(function (dependency) {
                    var data = findDependencyById(dependency.depends_on_variation_id);
                    if (data) {
                        dependency.group = data.group;
                        dependency.component = data.component;
                        dependency.variation = data.variation;
                    }
                });
                variation.assets.forEach(function (asset) {
                    asset.attribute_map = {};
                    asset.attribute_values.forEach(function (attr) {
                        var name = attrIdMap[attr.attribute_id];
                        if (name)
                            asset.attribute_map[name] = attr.value;
                    });
                });
            });
        });
    });
    return product;
};
exports.default = processProduct;
